/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/overlayscrollbars@1.13.1/css/OverlayScrollbars.min.css
 * - /npm/select2@4.0.13/dist/css/select2.min.css
 * - /npm/jquery-timepicker@1.3.3/jquery.timepicker.css
 * - /npm/@ttskch/select2-bootstrap4-theme@1.5.2/dist/select2-bootstrap4.min.css
 * - /npm/bootstrap-datepicker@1.10.0/dist/css/bootstrap-datepicker.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
